<template>
  <base-form
    @submit="handleSubmit"
    @cancel="handleCancel"
  />
</template>

<script>

  import { mapActions } from 'vuex';
  import dialogs from '@/services/dialogs.js';
  import { getLocalMessage } from '@/utils';

  export default {
    components: {
      BaseForm: () => import('./BaseForm')
    },
    data () {
      return {}
    },

    computed: {
    },

    created () {
    },

    methods: {
      ...mapActions({
        equipmentTemplateCreate: 'equipmentTemplate/create'
      }),

      getLocalMessage,

      handleSubmit (data) {
        this.equipmentTemplateCreate(data).then(() => {
          this.$router.push({name: 'equipmentTemplate'})
        }).catch( error => {
          if (error.data && error.data.code == 1100) {
            let messages = error.data.message.split('\'')
            console.log(messages)
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.resourceExistedErr', [this.getLocalMessage(this, 'equipmentTemplate', messages[1])]), { duration: 10, type: 'error' })
          } else {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.systemErr'), { duration: 10, type: 'error' })
          }
        })
      },

      handleCancel() {
        this.$router.go(-1)
      }
    }
  }
</script>
